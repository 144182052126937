/* TextComponents.css */

/* Basis-Stile für alle Textkomponenten */
.TextComponent,
.HeaderTextComponent {
  font-family: "Poppins", sans-serif;
}


/* Schriftgrößen für die verschiedenen Textgrößen */

/* XXL und h1 */
.text-xxl,
.heading-h1 {
  font-size: 44px; /* Desktop */
}
@media (max-width: 1023px) {
  .text-xxl,
  .heading-h1 {
    font-size: 40px; /* Tablet */
  }
}
@media (max-width: 767px) {
  .text-xxl,
  .heading-h1 {
    font-size: 36px; /* Mobile */
  }
}

/* XL und h2 */
.text-xl,
.heading-h2 {
  font-size: 28px; /* Desktop */
}
@media (max-width: 1023px) {
  .text-xl,
  .heading-h2 {
    font-size: 28px; /* Tablet */
  }
}
@media (max-width: 767px) {
  .text-xl,
  .heading-h2 {
    font-size: 28px; /* Mobile */
  }
}

/* L und h3 */
.text-l,
.heading-h3 {
  font-size: 24px; /* Desktop */
}
@media (max-width: 1023px) {
  .text-l,
  .heading-h3 {
    font-size: 24px; /* Tablet */
  }
}
@media (max-width: 767px) {
  .text-l,
  .heading-h3 {
    font-size: 24px; /* Mobile */
  }
}

/* M und h4 */
.text-m,
.heading-h4 {
  font-size: 16px; /* Desktop */
}
@media (max-width: 1023px) {
  .text-m,
  .heading-h4 {
    font-size: 14px; /* Tablet */
  }
}
@media (max-width: 767px) {
  .text-m,
  .heading-h4 {
    font-size: 14px; /* Mobile */
  }
}

/* S und h5 */
.text-s,
.heading-h5 {
  font-size: 12px; /* Desktop, Tablet und Mobile */
}

/* XS und h6 */
.text-xs,
.heading-h6 {
  font-size: 10px; /* Desktop, Tablet und Mobile */
}
