/* src/styles/variables.css */

:root {
  /* Red Shades */
  --basar-light-red: #faafb6;
  --basar-red: #e13746;
  --basar-dark-red: #ee3f4e;

  /* Green Shades */
  --basar-green: #17cf6b;
  --basar-light-green: #c9e8d7;
  --basar-dark-green: #0da553;

  /* Blue Shades */
  --basar-light-blue: #9cdffc;
  --basar-blue: #2dabe0;
  --basar-dark-blue: #0a5373;
  --basar-link-blue: #0085be;

  /* Greyscale Colors */
  --basar-white: #ffffff;
  --basar-light-grey: #ededed;
  --basar-grey: #9f9f9f;
  --basar-dark-grey: #494949;

  /* Black Shades */
  --basar-black-red: #190002;
  --basar-black-green: #00190c;
  --basar-black-blue: #00090d;
  --basar-black-grey: #891e27;
}
