.store-open-times {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 10px;
    border-radius: 8px;
  }
  
  .open-times-table {
    display: flex;
    flex-direction: column;
  }
  
  .open-times-row {
    display: flex;
    justify-content: space-between;
    align-content: space-between;
    margin-top: 1vw;

  }
  
  /* Aktueller Tag fett markieren */
  .highlight-today {
    font-weight: bold;
  }
  
  /* Wenn geöffnet → grün */
  .open-now {
    color: var(--basar-dark-green);
    font-weight: bold;
  }
  
  /* Wenn geschlossen → grau */
  .closed {
    color: var(--basar-grey);
  }
  
  /* Tag fett anzeigen */
  .day-cell {
    text-transform: capitalize;
  }
  
  /* Öffnungszeiten */
  .times-cell {
    text-align: right;
  }
  