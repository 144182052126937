.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-container h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
}

.contact-description {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  gap: 20px;
}

.whatsapp-button button {
  font-size: 1rem;
  padding: 12px 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.whatsapp-button.merchant button:hover {
  background-color: #1a2a5b; /* Darker shade for hover */
}

.whatsapp-button.customer button:hover {
  background-color: #b82525; /* Darker shade for hover */
}

.whatsapp-button button:active {
  transform: scale(0.98);
}
