
.input-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
}

.autocomplete-input-search {
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  border: 2px solid var(--basar-dark-blue);
  font-size: 18px;
  font-weight: bold;
  color: var(--basar-dark-blue);
  background-color: white;
  transition: all 0.3s ease-in-out;
  outline: none;
}

.autocomplete-input-search::placeholder {
  color: #7a7a7a;
  font-weight: normal;
}

.autocomplete-input-search:focus {
  border-color: var(--basar-dark-blue);
  box-shadow: 0 0 15px rgba(10, 83, 115, 0.5);
}

/* Hover Effekt mit dunklerem Blauschimmer */
.input-wrapper:hover .autocomplete-input-search {
  box-shadow: 0 0 20px rgba(10, 83, 115, 0.3);
}

/* Stile für die Google Autocomplete Liste */
.pac-container {
  background-color: #fff;
  border-radius: 10px;
  border-top: 2px solid var(--basar-dark-blue);
  font-family: Poppins, sans-serif;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.pac-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.pac-item:hover {
  background-color: var(--basar-light-blue);
}

.pac-item-query {
  font-weight: bold;
}

.pac-matched {
  font-weight: bold;
  color: var(--basar-dark-blue);
}

/* Stile für "Powered by Google" */
.pac-container:after {
  display: none !important;
}

/* Responsive Design */
@media (max-width: 768px) {
  .autocomplete-input-search {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .autocomplete-input-search {
    font-size: 14px;
    padding: 0.8rem;
  }
}