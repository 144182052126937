.blog-preview-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-preview-card img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Bild so skalieren, dass es die gesamte Kartenfläche abdeckt */
  max-height: 200px; /* Maximale Höhe des Bildes, um die Kartenhöhe nicht zu überschreiten */
}

.blog-preview-content {
  padding: 20px;
}

.blog-preview-content h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.blog-preview-content p {
  margin-bottom: 10px;
}

.blog-preview-content a {
  color: #007bff;
  text-decoration: none;
  display: block;
}

.blog-preview-content a:hover {
  text-decoration: underline;
}
.blog-preview-content .subtitle {
  /* Stil für den Untertitel im Blog-Preview */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; /* Breite je nach Bedarf anpassen */
}