/* Accordion.css - Verbesserte und responsive Styles */

.accordion-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  max-width: 700px;
  margin: 15px auto;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
}

.accordion-item:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.accordion-title {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--basar-light-grey);
  transition: background-color 0.3s;
}

.accordion-title:hover {
  background-color: var(--basar-dark-grey);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.accordion-title h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #333;
}

.accordion-title h3:hover {
  color: var(--basar-white) !important;
}


.accordion-title span {
  font-size: 20px;
  transition: transform 0.3s ease-in-out;
}

.accordion-title span.rotate {
  transform: rotate(180deg);
}

.accordion-content {
  padding: 15px;
  background-color: #fff;
  border-top: 1px solid #ddd;
  font-size: 16px;
  line-height: 1.5;
  color: var(--basar-dark-grey);
}

/* Responsive Anpassungen */
@media (max-width: 768px) {
  .accordion-title h3 {
    font-size: 18px;
  }
  .accordion-content {
    font-size: 14px;
  }
}
.accordion-title:hover h3 {
  color: var(--basar-white);
}
@media (max-width: 480px) {
  .accordion-title {
    padding: 12px;
  }
  .accordion-title h3 {
    font-size: 16px;
  }
  .accordion-content {
    font-size: 13px;
  }
}
