.app-store-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  
  .app-store-buttons a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }
  
  .app-store-buttons a:hover {
    transform: scale(1.05);
    opacity: 0.85;
  }
  
  .app-store-buttons svg {
    width: 160px;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .app-store-buttons {
      flex-direction: row;
      gap: 0.8rem;
    }
  
    .app-store-buttons svg {
      width: 140px;
    }
  }
  
  @media (max-width: 480px) {
    .app-store-buttons svg {
      width: 120px;
    }
  }
  