.custom-swiper-container {
  width: 100%;
  margin: auto;
  padding: 1vw;
}

.custom-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination {
  position: relative;
  margin-top: 10px;
}

.swiper-button-next,
.swiper-button-prev {
  color: var(--basar-red);
}

/* Ändert die Standardfarbe der Pagination-Dots */
.swiper-pagination-bullet {
  background-color: var(--basar-light-red) !important; /* Standardfarbe */
  opacity: 0.6; /* Leicht transparent */
}

/* Aktiver Pagination-Dot */
.swiper-pagination-bullet-active {
  background-color: var(--basar-red) !important; /* Farbe für den aktiven Punkt */
  opacity: 1; /* Volle Deckkraft */
}
