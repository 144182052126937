/* DeviceFrame.css */
.device-frame-wrapper {
  transform: scale(0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.device-frame-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-self: center;
  border: none;
}

.image-overlay {
  position: relative;
  width: 100%;
  height: 50vh;
  border: none;
}

.device-frame-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: none;
}

.image-gradient {
  position: absolute;
  top: 0;
  width: 100%;
  height: 70%;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 40%
  );
}

.device-frame-header {
  position: absolute;
  align-items: center;
  text-align: center;
  background-color: white;
  color: var(--basar-black);
  border: none;
  height: 18vh;
  width: 100%;
  justify-content: flex-end;
  align-content: center;
}
.device-frame-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: inherit;
  margin: 0;
  width: 95%;
  justify-self: center;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Maximale Zeilenanzahl */
  -webkit-box-orient: vertical;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.device-frame-type {
  font-size: 1rem;
  color: inherit;
  margin: 0;
}

.nav-bar {
  display: flex;
  width: 100%;
  justify-content: space-around;
  background: white;
  position: absolute;
  top: 50vh;
  height: 6vh;
  box-shadow: 0px 4px 6px rgba(182, 182, 182, 0.3);
  align-items: center;
}

.nav-bar-item {
  font-size: 1.2rem;
  cursor: pointer;
  margin: 0;
}

.nav-bar-item-focus {
  font-weight: bold;
  text-decoration: underline;
  color: var(--basar-red);
  margin: 0;
}

.nav-bar-item:hover {
  color: var(--basar-light-red);
}

.device-frame-text {
  text-align: left;
  width: 90%;
  margin: 0;
  margin-top: 5vh;

}

.coupon {
  margin-top: 13vh;
  pointer-events: none;
}

.device-frame-review-title {
  font-size: 1.5rem;
  margin-bottom: 1vh;
}

.device-frame-review {
  color: var(--basar-dark-grey);
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;

}
.device-frame-review-text {
  color: var(--basar-dark-grey);
  font-size: 1rem;
  margin: 0;
}

.review-card {
  margin: 0;
  text-align: left;
  width: 100%;
  justify-self: center;
  border: none;
}

.review-author {
  font-weight: bold;
  margin: 0;
  text-align: left;
}

.review-text {
  text-align: left;
  font-size: 1rem;
  color: var(--basar-dark-grey);
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  .device-frame-wrapper {
    transform: scale(0.6);
  }
}
