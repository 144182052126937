.coupon-card {
  display: flex;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  max-width: 500px;
  height: 200px;
}

.coupon-card:hover {
  transform: scale(1.05);
}

.coupon-image {
  width: 45%;
  height: auto;
  object-fit: cover;
}

.coupon-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 60%;
}

.coupon-price {
  font-size: 50px;
  font-weight: bold;
  color: var(--basar-red);
  margin: 0;
  text-align: left;
}

.coupon-description {
  font-size: 14px;
  color: #333;
  margin: 5px 0;
  text-align: left;
}

.coupon-button {
  background: var(--basar-red);
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  padding: 8px 12px;
  cursor: pointer;
  text-align: center;
  margin-top: 5px;
}

.coupon-button:hover {
  background: var(--basar-light-red);
  color: var(--basar-red);

}

/* 📱 Responsive Anpassung */
@media (max-width: 768px) {

}
