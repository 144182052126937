.store-reviews {
  margin-top: 20px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}

.store-reviews h3 {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.no-reviews {
  font-size: 1.2em;
  color: #777;
}

.review-card {
  padding: 2vh;
  border-bottom: 1px solid var(--basar-light-grey);
}

.review-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.review-name {
  color: #333;
  font-size: 1.1em;
}

.review-date {
  color: #999;
  font-size: 0.9em;
}

.review-rating {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.star-filled {
  color: var(--basar-light-red);
  font-size: 1.2em;
}

.star-empty {
  color: #ccc;
  font-size: 1.2em;
}

.review-comment {
  margin-top: 10px;
  font-size: 1em;
  color: #555;
}

.show-more-reviews {
  display: block;
  width: 30%;
  padding: 2vh;
  margin-top: 15px;
  border: none;
  background-color: var(--basar-red);
  color: white;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease-in-out;
  align-self: center;
  justify-self: center;
}

.show-more-reviews:hover {
  background-color: var(--basar-light-red);
}

/* Leere Sterne */
.star-outline {
  color: var(--basar-light-red);
  font-size: 1.5em;
}
