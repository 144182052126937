.fullscreen-overlay {
  position: fixed; /* Überdeckt ALLES */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white; /* Kann auch eine andere Farbe haben */
  z-index: 99999999; /* Stellt sicher, dass es über allem liegt */
  display: flex;
  justify-content: center;
  align-items: center;
}

.partner-detail-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-direction: row;
}

.span-group {
  display: flex;
  justify-content: center; /* Blau & Rot nebeneinander */
  flex-direction: column;
  align-items: center; /* Zentriert den gesamten Text */
  text-align: center;
  font-size: 1.5rem;
  padding-bottom: 0 !important; /* Falls Padding vorhanden ist */
  margin: 0 auto !important;
  
}
.text-phone-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Zentriert den Inhalt */
  justify-content: flex-start; /* Verhindert zu viel Platz nach unten */
  margin: 0 !important;
  padding: 0 !important;
}
.phone-mockup-container {
  margin-top: -10vh; /* Falls nötig, kann sogar negativ sein */
}


.span-text {
  display: inline-block;
}

.dark-blue {
  color: var(--basar-dark-blue);
  font-weight: bold;
}

.red {
  color: var(--basar-red);
  font-weight: bold;
}

.black-red {
  color: var(--basar-black-red);
  font-weight: bold;
}

.text-phone-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto; /* Falls Inhalt zu groß wird */
  margin: 0;
}

.contact-tally-form {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.contact-tally-form iframe {
  flex: 1;
  border: none;
}

@media (max-width: 768px) {
  .fullscreen-overlay{
  overflow-y: scroll;

  }
  .partner-detail-container {
    flex-direction: column;
  }

  .contact-tally-form {
    order: -1; /* Formular nach oben */
    flex: none;
    display: flex;
    flex-direction: column;
    min-height: 650px; /* Setzt eine vernünftige Mindesthöhe */
  }

  .contact-tally-form iframe {
    flex: 1;
    align-self: center !important;
  }

  .text-phone-container {
    flex: none;
    height: auto;
    overflow: hidden;
  }
  .span-text-container {
    align-items: center; /* Sorgt dafür, dass alles mittig bleibt */
  }

  .span-group {
    flex-direction: column;
    align-items: center;
  }
  .phone-mockup-container {
  }
}
