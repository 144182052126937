/* BlogAdvertisement.css */
.blog-advertisement {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
    border-radius: 8px; /* Abgerundete Ecken */
    box-shadow: 0 4px 50px rgba(0, 0, 0, 0.1);

  }
  
  .column {
    flex: 1;
    padding: 10px;
  }
  
  .column h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .column p {
    margin-bottom: 10px;
  }
  
  .column button {
    background-color: var(--basar-red); /* Button-Hintergrundfarbe */
    color: #fff; /* Button-Textfarbe */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .column button:hover {
    background-color: var(--basar-light-red); /* Button-Hintergrundfarbe bei Hover */
  }
  
@media (max-width: 768px) {
    .blog-advertisement {
      flex-direction: column;
    }
    .column {
        width: 100%; /* Stellt sicher, dass jede Spalte die volle Breite einnimmt */
        padding: 10px 0; /* Optional: Reduziert das Padding oben und unten */
      }
  }