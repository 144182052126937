/* FAQPage.module.css - Verbesserte und responsive Styles */

.faqContainer {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainTitle {
  font-size: 2.5rem;
  font-weight: bold;
  color: #222;
  margin-bottom: 40px;
  text-align: center;
}

.categorySection {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  margin-bottom: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.categoryTitle {
  color: #333;
  margin-bottom: 15px;
  font-size: 1.8rem;
  font-weight: 600;
}

.contactSection {
  margin-top: 50px;
  text-align: center;
}


/* Responsive Anpassungen */
@media (max-width: 768px) {
  .mainTitle {
    font-size: 2rem;
  }
  .categorySection {
    padding: 15px;
  }
  .contactButton {
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .mainTitle {
    font-size: 1.8rem;
  }
  .categoryTitle {
    font-size: 1.5rem;
  }

}
