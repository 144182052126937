/* 🌟 Allgemeines Styling für die gesamte Deals-Seite */
.browser-deals-container {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.browser-deals-two-column-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.browser-deals-header {
  width: 60%;
}

.autocomplete-container {
}

/* 🎟️ Kategorie-Slider */
.category-slider {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.category-item {
  background: #f5f5f5;
  border: none;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s ease;
  white-space: nowrap;
}

.category-item.active,
.category-item:hover {
  background: var(--basar-red);
  color: white;
}

/* 🎟️ Stores Grid */
.stores-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

/* 📌 Kein Ergebnis */
.no-results {
  font-size: 18px;
  font-weight: bold;
  color: #666;
}

.alternative-results {
  font-size: 18px;
  font-weight: bold;
  color: var(--basar-dark-grey);
  margin-top: 10px;
}

/* 🚀 Responsive Anpassungen */
@media (max-width: 768px) {
  .browser-deals-two-column-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5vh;
  }
  .browser-deals-header {
    width: 100%;
  }

  .stores-grid {
    grid-template-columns: 1fr;
  }

  .search-bar-osm input {
    width: 100%;
  }

  .category-slider {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 5px;
  }
}
