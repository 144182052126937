/* 🏪 Store Profile */
.store-profile {
  width: 80%;
  margin: auto;
}

.store-name {
  all: unset; /* Entfernt ALLE Standard-Button-Stile */
  display: inline-block; /* Damit Breite sich anpasst */
  font-size: inherit; /* Beibehaltung der Schriftgröße */
  cursor: pointer; /* Damit es klickbar bleibt */
  transition: opacity 0.2s ease-in-out;
}

.store-name:hover {
  opacity: 0.7; /* Dezenter Hover-Effekt */
}

/* 🏷️ Store Header */
.store-profile-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 10vh; /* Platz für die Navbar */
  z-index: 10000;
  background-color: white;
}

.store-profile-header {
  position: sticky;
  top: 0; /* Oben fixiert */
  width: 100%;
  background-color: white;
  z-index: 10000;
  padding: 10px 0;
  transition: box-shadow 0.3s ease-in-out;
}

/* 📌 Navbar */
.store-profile-navbar {
  display: flex;
  gap: 3vw;
  align-self: center;
  position: sticky;
  justify-self: center;
}
.store-profile-nav-item {
  cursor: pointer;
  padding: 1vh 2vw;
  font-weight: bold;
  color: var(--basar-dark-grey);
  border: none; /* Entfernt jegliche Border */
  background: none;
  transition: border-bottom 0.3s ease, color 0.3s ease;
  border-bottom: 3px solid transparent; /* Standard unsichtbar */
}

.store-profile-nav-item:hover,
.store-profile-nav-item.active {
  border-bottom: 3px solid var(--basar-light-red); /* Aktiv rote Unterstreichung */
  color: var(--basar-red);
}

/* 📍 Store Infos */
.store-profile-info-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.store-profile-info-row-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.store-profile-subinfo {
  font-size: medium;
  color: inherit;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
}

.store-profile-subinfo:hover {
  font-size: medium;
  color: inherit;
  text-decoration: underline;
  opacity: 0.7; /* Dezenter Hover-Effekt */
}

.link-text {
  color: inherit;
  text-decoration: inherit;
}

.store-profile-icon {
  color: var(--basar-dark-grey);
  margin-right: 1vh;
}

/* 📌 Sections */
.store-section {
  padding: 50px 0;
  border-top: 1px solid var(--basar-light-grey);
}
.store-section-title {
  text-align: left;
  font-size: xx-large;
  color: var(--basar-red);
}

.store-map-container {
  width: 100%;
  height: 40vh;
  cursor: pointer;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  /* Runde Seiten + Spitze unten */
  border-radius: 100% 100% 10% 10%;
}


/* Sicherstellen, dass die Map den Marker ausfüllt */
.store-map {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

/* Hover-Effekt für interaktives Klicken */
.store-map-container:hover {
  opacity: 0.9;
}

.info-image-section {
  display: flex;
  flex-direction: column;
}

/* 📌 Infos Section mit Map & Öffnungszeiten */
.store-section-double {
  padding: 40px 0;
  border-top: 1px solid #ddd;
  text-align: center;
}

.info-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2vw;
  margin-top: 20px;
}

/* 🕒 Öffnungszeiten Container */
.open-times-container {
  text-align: left;
  border-radius: 10px;
  background-color: white;
  width: 100%;
}

.open-times-container h3 {
}

.open-times-list {
  list-style: none;
  padding: 0;
}

.open-time-item {
  display: flex;
  border-bottom: 1px solid #ddd;
}

.open-time-item:last-child {
  border-bottom: none;
}

.open-time-day {
  font-weight: bold;
}

.open-time-hours {
  color: #666;
}

/* 🎯 Responsive Anpassungen */
@media (max-width: 768px) {
  .store-profile {
    width: 100%;
    margin: auto;
  }
  .info-container {
    flex-direction: column;
    align-items: center;
  }

  .store-map-container,
  .open-times-container {
    width: 100%;
  }
  /* 🏷️ Store Header */
  .store-profile-header {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    width: 100vw;
    justify-self: center !important;
    text-align: center !important;
    align-content: center !important;
  }
  .store-profile-navbar {
    width: 100vw;
    align-items: center;
    justify-self: center;
    align-self: center;
    justify-content: space-evenly;
  }
  /* 📍 Store Infos */
  .store-profile-info-row {
    flex-direction: column !important;
    padding: 1vw;
  }

  .store-profile-subinfo {
    font-size: medium;
  }
}
