.three-step-container {
  text-align: center;
  margin: 0 auto;
}

.steps-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.step-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  max-width: 400px;
  align-content: space-between;
  justify-content: space-around;
  background-color: white;
}

.step-content {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-description {
  display: flex;
  flex-direction: row;
}

.step-icon-box {
  position: absolute;
  top: 21vh;
  width: 100px;
  height: 100px;
  border-radius: 500px;
  align-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: white;
}

.step-icon {
  width: 50px;
  height: 50px;
  color: white;
  background-color: white;
  margin-top: 10px;
}

/* Responsive Anpassungen */
@media (max-width: 768px) {
  .steps-section {
    flex-direction: column;
  }

  .step-column {
    width: 80%;
    margin-bottom: 20px;
  }

  .step-image {
    max-width: 50%;
    height: auto;
  }
}


@keyframes slideUp {
  from {
    transform: translateY(-100px); /* Startposition unten */
    opacity: 0;
  }
  to {
    transform: translateY(0); /* Endposition oben */
    opacity: 1;
  }
}

.step-image {
  max-width: 50vw;
  height: 40vh;
  opacity: 0; /* Unsichtbar beim Start */
  animation: slideUp 0.8s ease-out forwards;
}
