/* Navbar.module.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 100000;
}


.logo svg {
  height: 10vh;
  width: auto;
  cursor: pointer;
}

.menuIcon {
  display: block;
  font-size: 3em;
  cursor: pointer;
  transition: color 0.3s;
  align-items: center;
  justify-content: center;
  justify-items: center;
  
}

.menuIcon:hover {
  color: var(--basar-light-red);
}

.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modalContent {
  background-color: #fff;
  width: 100%;
  height: 100%;
  text-align: center;
  align-content: center;
  align-items: center;
}

.modalLinks {
  list-style: none;
  padding: 0;
}

.modalLinks li a {
  display: block;
  padding: 4vh;
  text-decoration: none;
  font-size: 2em;
  font-weight: bold;

  color: #333;
  transition: background-color 0.3s;
}

.modalLinks li a:hover,
.modalLinks li a.active {
  background-color: #f0f0f0;
  color: var(--basar-light-red);
  font-weight: bold;
}
