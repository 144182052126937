.blog-post {
    margin: 0 auto;
    padding: 20px;
    width: 80%;
  }
  
  .blog-title {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  .blog-subtitle {
    font-size: 1rem;
    color: var(--basar-dark-grey);
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  
  .blog-meta {
    color: #666;
    font-size: 0.9rem;
    margin-bottom: 20px;
  }
  
  .blog-image {
    width: 50%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .blog-content {
    line-height: 1.6;
    font-size: 1.1rem;
  }
  