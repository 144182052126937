.store-image-slider {
  width: 80vw;
  height: 60vh;
  overflow: hidden;
  border-radius: 8px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

/* Navigationspfeile nur anzeigen, wenn mehr als zwei Bilder */
.swiper-button-prev,
.swiper-button-next {
  color: var(--basar-red);
  background: rgba(255, 255, 255, 0.5);
  width: 3vw;
  height: 3vw;
  border-radius: 100%;
  transition: background 0.3s;
}

/* Hover-Effekt */
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background: rgba(255, 255, 255, 0.8);
}

/* Größe der Pfeile */
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 1vw;
  font-weight: bolder;
}

@media (max-width: 768px) {
  .store-image-slider {
    width: 100vw;
    max-width: 100vw;
    align-self: center;
    justify-self: center;
  }

  .slider-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: var(--basar-red);
    background: rgba(255, 255, 255, 0.5);
    width: 10vw;
    height: 10vw;
    border-radius: 100%;
    transition: background 0.3s;
  }
}
