/* Container für zentrierte Inhalte */
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;
  }
  
  /* Rahmen für das Formular */
  .card {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  /* Überschrift */
  .title {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  /* Eingabefelder */
  .input {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* Schaltfläche */
  .button {
    width: 100%;
    padding: 12px;
    background-color: var(--basar-red);
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Fehlermeldung */
  .errorMessage {
    color: red;
    margin-bottom: 15px;
    text-align: center;
  }
  
  /* Erfolgsmeldung */
  .successMessage {
    color: green;
    margin-bottom: 15px;
    text-align: center;
  }
  