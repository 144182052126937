.basar-game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* 🔥 Zwei-Spalten Layout für Desktop */
.intro-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  gap: 200px;
}

/* 🎥 Video-Container */
.video-container {
transform: scale(0.8);
}

/* 📝 Erklärung + Carousel */
.game-explanation {
}

/* 📲 Download Section */
.download-app-section {
  margin-top: 40px;
}

.badge-container-game {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.badge-item {
  width: 250px;
  transition: transform 0.3s ease;
}

.badge-item:hover {
  transform: scale(1.1);
}

.gif-horizontal-basar-game {
  align-self: center;
  justify-self: center;
  margin-bottom: -100px;
}
.gif-game-img {
  width: 800px;
}
/* 🔄 Responsive Anpassung für Mobile */
@media (max-width: 768px) {
  .gif-game-img {
    width: 500px;
  }
  .intro-section {
    flex-direction: column;
  }

  .badge-container {
    flex-direction: column;
    align-items: center;
  }

  .badge-item {
    width: 130px;
  }
}
