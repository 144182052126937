.autocomplete-container {
  width: 100%;
  max-width: 400px;
  margin: auto;
  position: relative;
}

/* 🔎 Suchfeld */
.osm-input-container {
  display: flex;
  align-items: center;
  border: 1px solid var(--basarColorBlue);
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  
}

.osm-search-input {
  flex: 1;
  height: 40px;
  font-size: 16px;
  border: none;
  outline: none;
  background: transparent;
}

.osm-search-icon {
  margin-right: 8px;
}

.clear-button {
  background: none;
  border: none;
  cursor: pointer;
}

/* 📍 OSM-Ergebnisse */
.results-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  padding: 10px;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.result-item {
  padding: 12px;
  cursor: pointer;
  border-radius: 8px;
  transition: background 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
}

.result-item:hover {
  background-color: var(--basarLightGrey);
}

.result-text {
  font-weight: bold;
  font-size: 16px;
}

.address-text {
  font-size: 14px;
  color: var(--basarDarkGrey);
}

/* 🚀 Responsive */
@media (max-width: 768px) {
  .autocomplete-container {
    width: 100%;
  }
}
