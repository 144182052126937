.footer {
    padding: 20px;
    background-color: #f8f8f8;
  }
  
  .footer-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .footer-column {
    flex: 1;
    min-width: 200px;
  }
  
  .footer-column h3 {
    margin-bottom: 10px;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-column ul li {
    margin-bottom: 5px;
  }
  
  .footer-column ul li a {
    text-decoration: none;
    color: #000;
  }
  
  .footer-column ul li a:hover {
    text-decoration: underline;
  }
  
  .footer-logo {
    text-align: center;
    margin-top: 20px;
  }
  
  .footer-logo img {
    max-width: 200px;
  }
  
  @media (max-width: 767px) {
    .footer-grid {
      display: grid;
      gap: 20px;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  