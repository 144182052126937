/* PricePage.css - Verbesserte und responsive Styles */

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-container {
  text-align: center;
  margin-bottom: 40px;
}

.subheader-container {
  width: 70%;
  margin: 20px auto;
  font-size: 18px;
  line-height: 1.6;
  color: #555;
}

.price-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  width: 100%;
  margin-top: 40px;
  padding-bottom: 40px;
}

.call-to-action {
  margin: 60px 0;
  padding: 20px;
  text-align: center;
  background-color: #f0f8ff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Responsive Anpassungen */
@media (max-width: 1024px) {
  .subheader-container {
    width: 85%;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 20px;
  }
  .subheader-container {
    width: 100%;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .header-container h2 {
    font-size: 22px;
  }
  .subheader-container {
    font-size: 14px;
  }
}