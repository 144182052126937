.blog-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .blog-preview {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .blog-image {
    width: 100%;
    height: auto;
  }
  
  .blog-info {
    padding: 20px;
  }
  
  .blog-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .blog-lead-text {
    margin-bottom: 10px;
  }
  
  .blog-date {
    color: #666;
    font-size: 0.9rem;
  }
  

  .blog-list {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }