/* 📌 Store Card */
.store-card {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  position: relative;
  max-width: 500px;

}

.store-card:hover {
  transform: scale(1.05);
}


.dummy-card {
  order: -1; /* Immer als erstes anzeigen */
  background-color: #f8f8f8; /* Heller Hintergrund für bessere Unterscheidung */
  opacity: 0.9;
  pointer-events: none; /* Verhindert Klicks auf die Dummy-Karte */
}


.store-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.coupon-header-text {
  position: absolute;
  top: 180px;
  left: 10px;
  background-color: var(--basar-red);
  color: white;
  padding: 5px 10px;
  border-radius: 6px;
  font-weight: bold;
  font-size: large;
}

.store-info-box {
  padding: 15px;
  text-align: left;
}

/* 🏷️ Coupon Text */
.coupon-body-text {
  font-size: medium;
  color: black;
}

.coupon-store-address {
  font-size: small;
  color: gray;
  margin-bottom: -2vh;
}

.coupon-store-name {
  font-size: medium;
  font-weight: bold;
  margin-bottom: -1vh;
}

.coupon-store-category {
  font-size: small;
  color: var(--basar-dark-grey);
  margin-bottom: -2vh;
}

.store-subcategories {
  font-size: small;
  color: var(--basar-grey);
}
