.benefit-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1vw;
    justify-self: center;
    align-self: center;
  }

  .benefit-item {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 35vw;
    justify-self: center;
  }
  
  .benefit-icon {
    margin-bottom: 10px;
  }
  
  .benefit-icon img {
    width: 50px; /* Passe die Größe des Icons nach Bedarf an */
  }
  
  .benefit-text h2 {
    font-size: 1.5rem;
    color: var(--basar-red); /* Passe die Farbe nach Bedarf an */
    margin-bottom: 10px;
  }
  
  .benefit-text p {
    font-size: 1rem;
    color: #666666; /* Passe die Farbe nach Bedarf an */
  }