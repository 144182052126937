.partner-page-container {
  display: flex;
  min-height: 50vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden; /* Verhindert horizontales Scrollen */

}

.partner-search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  align-self: center;
  justify-self: center;
  padding-bottom: 10vh;

}

.two-grid-section-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  position: relative;
  gap: 20px;
}

.left-grid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.left-grid-container h1 {
  font-size: 2.5rem;
  color: #0a5373;
  margin-bottom: 20px;
}

.left-grid-container p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.right-grid-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-grid-image {
  width: 100%;
  height: auto;
  max-width: 400px;
}

/* Benefits Section */
.section-container {
  width: 100vw;
  align-items: center !important;
  justify-items: center !important;
  justify-self: center !important;
  padding: 40px 20px;
}

.benefit {
  background-color: var(--basar-dark-blue);
  text-align: center;
}

.section-title {
  color: white;
  text-align: center;
}

.invert {
  color: var(--basar-dark-blue);
}

.left-column {
  display: flex;
  justify-content: center;
}
.right-column {
  text-align: center;
}

.section-image-phone {
  width: 80%;
  align-self: center;
  align-content: center;
}

/* 📱 Mobile Optimierung */
@media (max-width: 768px) {
  .two-grid-section-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    padding: 50px 20px;
    position: relative;
    background-image: url("../assets/images/local_vendors.png"); /* Hintergrundbild nur auf Mobile */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .section-container {
    width: none;
  }

  /* Abdunklung nur für Mobile */
  .two-grid-section-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Abdunklung */
    z-index: 1;
  }
  .partner-page-section-title {
    color: white !important;
    margin: 0;
  }
  /* Linke Sektion mit Text bleibt sichtbar */
  .left-grid-container {
    position: relative;
    z-index: 2; /* Damit der Text über dem Overlay liegt */
    color: white;
  }
  .left-grid-container-text {
    color: white;
  }

  /* Das Bild in .right-grid-container auf Mobile verstecken */
  .right-grid-container {
    display: none;
  }

  .partner-search-container {
    width: 80%; /* Etwas größere Breite für bessere Darstellung */
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* Sorgt für horizontale Zentrierung */
  }

}
